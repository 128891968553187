import React, { useState, FormEvent } from 'react';
import { BiBlock, BiCheck } from 'react-icons/bi';

const CreditCardForm: React.FC = () => {
  const [cardNumber, setCardNumber] = useState<string>('');
  const [validationMessage, setValidationMessage] = useState<string>('');

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const response = await fetch('https://server.greenleafgames.workers.dev/credit-card-luhn-verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ card_number: cardNumber }),
    });

    const result = await response.json();
    setValidationMessage(result.validCardNumber.toString());
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="items-center">
          <label htmlFor="cardNumber" className="text-slate m-8 px-4">Credit Card Number:</label>
          <input
            className="text-slate m-2 border-2 border-slate-800 rounded-md"
            type="text"
            id="cardNumber"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            required
          />
          {validationMessage && validationMessage === 'true' ? <BiCheck className="inline-block" /> : validationMessage === 'false' ? <BiBlock className="inline-block" /> : <div className="hidden"></div>}
        </div>
        <button className="my-2 mx-64 rounded-md text-white font-bold py-2 px-4 bg-slate-700" type="submit">Submit</button>
      </form>
      {validationMessage && <p className="mx-32 px-16 my-4">{validationMessage === 'true' ? 'Credit Card Number is valid!' : 'Credit Card Number is invalid!'}</p>}
    </div>
  );
};

export default CreditCardForm;
