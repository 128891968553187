import React from 'react';
import CreditCardForm from './components/CreditCardForm';

const App: React.FC = () => {
  return (
    <div className="flex flex-col overflow-hidden items-center justify-center h-screen bg-sky-100" >
      <h1 className="text-3xl">Credit Card Validator utilizing Luhn's Algorithm:</h1>
      <CreditCardForm />
    </div>
  );
};

export default App;
